import * as React from "react";
import { useEffect, useState } from "react";
import { Card, CardHeader } from "@mui/material";
import ExamDocumentTable, { IncludedExamLevel } from "./ExamDocumentTable";
import Season, { Exam } from "../../domain/Season";
import Account, { AccountRole } from "../../domain/Account";
import SeasonState from "../../domain/SeasonState";
import ExamLevel from "../../domain/ExamLevel";
import { getMyAssignments } from "../../gateway/marking";
import { useAccount } from "../account/AccountContext";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CardContentNoPadding from "../common/CardContentNoPadding";
import { UUID } from "../../domain/UUID";
import { useManagedCentres } from "../centres/ManagedCentresContext";

interface Props {
  readonly season: Season,
  readonly onlyExamIds?: UUID<Exam> | UUID<Exam>[]
}

export default function ExamDocumentDownloadCard({ season, onlyExamIds }: Props) {
  const { account } = useAccount();
  const { managedCentres } = useManagedCentres();
  const [hasAssignments, setHasAssignments] = useState<boolean>(false);

  const managesAnyCentres = managedCentres !== undefined && managedCentres.length > 0;
  if (onlyExamIds !== undefined && !Array.isArray(onlyExamIds)) {
    onlyExamIds = [onlyExamIds];
  }

  useEffect(() => {
    if (account == undefined) {
      return;
    }

    if (Account.hasRole(account, AccountRole.MARKER) && season.exams[ExamLevel.ADVANCED] !== undefined) {
      getMyAssignments(season.exams[ExamLevel.ADVANCED].examId).then(a => setHasAssignments(a.length > 0));
    }
  }, [season.id, account?.id]);

  if (account == undefined) {
    return null;
  }

  const levels = computeIncludedExamLevels(season, account, hasAssignments, managesAnyCentres, onlyExamIds);

  if (levels.length === 0) {
    return null;
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title="Exam documents"
        subheader="Download exam questions and solutions"
        avatar={<AssignmentIcon />}
        sx={{ bgcolor: 'grey.200' }}
      />
      <CardContentNoPadding>
        <ExamDocumentTable seasonId={season.id} levels={levels} />
      </CardContentNoPadding>
    </Card>
  );
}

function computeIncludedExamLevels(season: Season, account: Account, hasAssignments: boolean, managesAnyCentres: boolean, onlyExamId: UUID<Exam>[] | undefined): IncludedExamLevel[] {
  const permittedLevels = computePermittedExamLevels(season.state, account, hasAssignments, managesAnyCentres);

  if (onlyExamId === undefined) {
    return permittedLevels;
  }

  return permittedLevels.filter(level => onlyExamId.includes(season.exams[level.level].examId));
}

function computePermittedExamLevels(seasonState: SeasonState, account: Account, hasAssignments: boolean, managesAnyCentres: boolean): IncludedExamLevel[] {
  if (Account.hasRole(account, AccountRole.ADMIN)) {
    return ExamLevel.roundOneValues().map(level => ({ level, includeSolutions: true }));
  }

  if (SeasonState.isBefore(seasonState, SeasonState.ROUND_1_TEST_PERIOD)) {
    return [];
  }

  if (SeasonState.isBetween(seasonState, SeasonState.ROUND_1_TEST_PERIOD, SeasonState.ROUND_1_ADVANCED_MARKING)) {
    if (Account.hasRole(account, AccountRole.CENTRE_MANAGER) && managesAnyCentres) {
      // All round one questions
      return ExamLevel.roundOneValues().map(level => ({ level, includeSolutions: false }));
    }

    return [];
  }

  if (SeasonState.isOrIsAfter(seasonState, SeasonState.ROUND_1_ADVANCED_MARKING)) {
    let includedLevels: Map<ExamLevel, boolean> = new Map();
    if (Account.hasRole(account, AccountRole.CENTRE_MANAGER)) {
      // All round one questions, non-Advanced solutions
      ExamLevel.roundOneValues().forEach(level => includedLevels.set(level, level !== ExamLevel.ADVANCED));
    }

    if (Account.hasRole(account, AccountRole.MARKER) && hasAssignments) {
      // Advanced questions and solutions (if user is also centre manager, this supplements the above)
      includedLevels.set(ExamLevel.ADVANCED, true);
    }

    return Array.from(includedLevels.entries()).map(([l, s]) => ({ level: l, includeSolutions: s }));
  }

  // TODO handle later SeasonStates - i.e. results onwards probably all documents should be available

  return [];
}
