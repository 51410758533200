import * as React from 'react';
import { useEffect, useState } from 'react';
import CentreDetailsCard from './CentreDetailsCard';
import Centre, { CentreDetails } from '../../domain/Centre';
import { getCentre, updateCentre } from '../../gateway/centres';
import UpdateCentreDetailsDialog from './UpdateCentreDetailsDialog';
import { UUID } from "../../domain/UUID";
import { Stack } from "@mui/material";
import { useCurrentSeason } from "../exams/SeasonContext";
import CentreExamDocumentsCard from "./CentreExamDocumentsCard";
import SeasonState from "../../domain/SeasonState";
import StudentsSection from "./students/StudentsSection";
import SelfServeCentreMarkingAlert from "./SelfServeCentreMarkingAlert";

interface Props {
  readonly centreId: UUID<Centre>
}

export default function CentreManagement({ centreId }: Props) {
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [centre, setCentre] = useState<Centre>();
  const { currentSeason } = useCurrentSeason();

  useEffect(() => {
    getCentre(centreId)
      .then(setCentre)
      .catch(() => {
        setCentre(undefined);
      });
  }, [centreId]);

  const saveCentreDetails = (centreDetails: CentreDetails) => {
    updateCentre(centreId, centreDetails)
      .then(setCentre)
      .then(() => setShowUpdateDialog(false));
  }

  if (centre === undefined) {
    return <></>;
  }

  return (
    <>
      <CentreDetailsCard centre={centre} actionText="Update details" onAction={() => setShowUpdateDialog(true)} />
      <Stack spacing={1} sx={{ p: 1 }}>
        {SeasonState.isBetween(currentSeason?.state, SeasonState.ROUND_1_ADVANCED_MARKING, SeasonState.COMPLETE) && <SelfServeCentreMarkingAlert centreId={centreId} />}
        {SeasonState.isBetween(currentSeason?.state, SeasonState.ROUND_1_TEST_PERIOD, SeasonState.ROUND_1_ADVANCED_RESULTS_PUBLICATION) && <CentreExamDocumentsCard centreId={centre.id} editable={SeasonState.isBefore(currentSeason?.state, SeasonState.ROUND_1_ADVANCED_RESULTS_VERIFICATION)} />}
        <StudentsSection centreId={centre.id} />
        <UpdateCentreDetailsDialog
          open={showUpdateDialog}
          dismiss={() => setShowUpdateDialog(false)}
          save={saveCentreDetails}
          centre={centre}
        />
      </Stack>
    </>
  );
}
